<template>
  <div>

    <div class="feedback">
      <div class="feedback_in" v-if="type === 'ID_CARD_REVIEWING'">
        <img src="../../../src/assets/images/ico_examine.svg" alt="">
        <span>身份证审核中</span>
        <label>请稍微等候人工审核影像，审核结果见通知短信，此过程预计半小时，通过后即可继续办理</label>
        <button class="back" @click="goBack">知道了</button>
      </div>

      <div class="feedback_in" v-if="type === 'SUCCESS'">
        <img :src="require('../../../src/assets/images/ico_success.svg')" alt="">
        <span>电子钱包开通成功</span>
        <label></label>
        <button class="back" @click="goBack">知道了</button>
      </div>

      <div class="feedback_in" v-if="type === 'UPGRADE_SUCCESS'">
        <img :src="require('../../../src/assets/images/ico_success.svg')" alt="">
        <span>电子钱包升级成功</span>
        <label></label>
        <button class="back" @click="goBack">知道了</button>
      </div>

      <div class="feedback_in" style="display:none" v-if="type === 'REVIEWING'">
        <img src="../../../src/assets/images/ico_success.svg" alt="">
        <span>提交成功</span>
        <label>您已成功提交申请资料，我行将于 1~3 个工作日内完成开通审核，审核结果以短信通知为准，短信发送号码 <font>95508</font>。如有疑问可进入一天膳事平台在线客服咨询。</label>
      </div>

      <div class="feedback_in" v-if="type === 'FAIL'">
        <img src="../../../src/assets/images/ico_fail.svg" alt="">
        <span>开通失败</span>
        <label>{{ msg }}</label>
<!--        <button class="block">常见问题指引</button>-->
<!--        <button class="block">开通教学视频</button>-->
        <button class="back" @click="goBack">返回</button>
      </div>

      <div class="feedback_in" v-if="type === 'UPGRADE_FAIL'">
        <img src="../../../src/assets/images/ico_fail.svg" alt="">
        <span>审核失败</span>
        <label>{{ msg }}</label>
        <button class="block" style="width:50%" @click="routeToAccountUpgradeResubmitting">重新上传身份证图片</button>
        <button class="back" @click="goBack">返回</button>
      </div>

    </div>

  </div>
</template>


<style scoped>
</style>

<script>
import {post} from "../../utils/http";

export default {
  data() {
    return {
      type: "", //显示的类型
      msg: "",
      timer: null
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.msg = this.$route.query.msg;
    if (!this.msg) {
      this.msg = '请重新开通，谢谢';
    }

    if (this.type === 'ID_CARD_REVIEWING') {
      this.getUpgradeStatus();
    }

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      // this.$router.push('/account/detail');
      this.$router.push('/customer_user_account');

      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    getUpgradeStatus() {
      this.timer = setInterval(() => {
        // 获取审核状态
        post('api/customer/account/v2/find', {}, true).then(res => {
          if (res.data.upgradeStatus === '03') {
            this.type = 'UPGRADE_SUCCESS';

          } else if (res.data.upgradeStatus === '04') {
            this.type = 'UPGRADE_FAIL';
            this.msg = res.data.remark;

          }
        })
      }, 10000)
    },
    routeToAccountUpgradeResubmitting() {
      this.$router.replace({path: "/AccountUpgradeResubmitting"})
    }
  }

};
</script>
